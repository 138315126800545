<template>
  <div class="row mb-2">
    <!-- DETAIL USER -->
    <div class="col-md-8">
      <b-card no-body class="h-100">
        <b-card-header>
          <b-card-title>Detail User</b-card-title>
        </b-card-header>
        <div class="mx-auto mt-2">
          <b-avatar
            size="100px"
            :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
          />
        </div>
        <h4 class="mx-auto mt-2">Kementrian Kesehatan Indonesia</h4>
        <b-card-body
          class="mt-3"
          style="margin-left: 10rem; margin-right: 10rem"
        >
          <!-- NAME -->
          <div class="nama mb-2">
            <h6>Nama :</h6>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserCheckIcon" class="text-primary" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" value="Kementrian Kesehatan" />
            </b-input-group>
          </div>

          <!-- USERNAME -->
          <div class="username mb-2">
            <h6>Username :</h6>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon" class="text-primary" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" value="kemenkes.admin" />
            </b-input-group>
          </div>

          <!-- NO KONTAK -->
          <div class="kontak mb-2">
            <h6>No Kontak :</h6>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="PhoneIcon" class="text-primary" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" value="(022) 1234567" />
            </b-input-group>
          </div>

          <!-- EMAIL -->
          <div class="email">
            <h6>Email :</h6>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" class="text-primary" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search"
                value="kementriankesehatan@gmail.co.id"
              />
            </b-input-group>
          </div>

          <!-- SAVE BUTTON -->
          <b-button variant="primary" class="mt-2 float-right">
            Simpan
          </b-button>
        </b-card-body>
      </b-card>
    </div>

    <!-- CHANGE PASSWORD -->
    <div class="col-md-4">
      <b-card no-body class="h-100">
        <b-card-header>
          <b-card-title>Ubah Password</b-card-title>
        </b-card-header>
        <b-card-body class="mt-2">
          <!-- OLD PASSWORD -->
          <h6>Password Lama :</h6>
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="KeyIcon" class="text-primary" />
            </b-input-group-prepend>
            <b-form-input placeholder="Password Lama" />
          </b-input-group>

          <!-- NEW PASSWORD -->
          <h6>Password Baru :</h6>
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="KeyIcon" class="text-primary" />
            </b-input-group-prepend>
            <b-form-input placeholder="Password Baru" />
          </b-input-group>

          <!-- CONFIRM NEW PASSWORD -->
          <h6>Konfirmasi Password Baru :</h6>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="KeyIcon" class="text-primary" />
            </b-input-group-prepend>
            <b-form-input placeholder="Konfirmasi Password Baru" />
          </b-input-group>

          <!-- SAVE BUTTON -->
          <b-button variant="primary" class="mt-2 float-right">
            Ubah Password
          </b-button>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  name: "Profile",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
  },
};
</script>